<template>
  <div class="advanced-select" v-click-outside="deactivate" :class="{ 'open-up': openUp }">
    <div class="input-group">
      <input v-if="name" :name="name" type="hidden" :value="query" />
      <input
        class="form-control"
        dir="ltr"
        :type="inputType"
        :aria-label="label"
        :placeholder="placeholder"
        v-model="query"
        @focus="activate"
        @input="handleInput($event)"
        @paste="handleInput"
        @keyup.esc="deactivate"
	@click="activate"
	ref="theInput"
      />
    </div>
    <div class="form-select" dir="ltr" :aria-label="label" v-if="isActive && useFormSelect">
      <div
        class="form-select-option"
        v-for="option in filteredOptions"
        :key="option.k || option"
        :value="option.k || option"
        @click="handleSelect(option.k||option,$event)"
        v-html="option.hl||option"
      >
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineExpose, defineProps, defineEmits, ref, onMounted, watch } from "vue";
import vClickOutside from "@/directives/click-outside";

const props = defineProps({
  inputType: {
    type: String,
    default: "text",
  },
  openUp: {
    type: Boolean,
    default: false,
  },
  dummy: {
    type: Boolean,
  },
  label: { type: String },
  options: { type: Array },
  placeholder: { type: String },
  useFormSelect: {
    type: Boolean,
    default: true,
  },
  name: {
    type: String,
    required: false,
    default: null
  }
});

const emit = defineEmits(["select", "selected"]);

const filteredOptions = ref([]);
const query = ref(localStorage.getItem(`advancedSelectQuery_${props.label}`) || "");
const isActive = ref(false);
const theInput = ref(null);

onMounted(() => {
  filterOptions();
});

watch(() => props.dummy, (val) => {
  if (val) {
    query.value = "";
  }
});

function clear() {
  query.value = "";
}

function activate() {
  isActive.value = true;
}

function deactivate() {
  isActive.value = false;
}

function filterOptions({ value: query } = { value: "" }) {
  const q = query && query.trim().toLocaleLowerCase();
  if (!q) {
    filteredOptions.value = props.options;
  } else {
    const qq = q.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const re = RegExp("(^|\\s|[^\\u0590-\\u05FF])(" + qq + ")", "gi");
    filteredOptions.value = props.options
      .map((x) => {
        const hl = x.replaceAll(re, "$1<b>$2</b>");
        if (hl == x) return null;
        return { k: x, hl };
      })
      .filter((x) => x);
  }
}

function handleSelect(option) {
  emit("select", (query.value = option));
  handleInput();
  theInput.value.focus();
  const il = theInput.value.length;
  theInput.value.setSelectionRange(il, il);
  deactivate();
  emit("selected");
}

function handleInput($event) {
  emit("select", query.value);
  localStorage.setItem(`advancedSelectQuery_${props.label}`, query.value);
  return $event && filterOptions($event.target);
}

function focus() {
  theInput.value.focus();
}

defineExpose({
  focus,
  clear,
});

</script>

<style scoped>
::placeholder {
  color: rgba(185, 84, 42, 0.68);
}

.open-up .form-select {
  bottom: 100%;
}

.advanced-select {
  margin: 8px;
  position: relative;
}

.advanced-select .form-select {
  background-image: none;
  max-height: 150px;
  overflow: auto;
  position: absolute;
  z-index: 100;
}

.advanced-select .form-select-option {
  cursor: pointer;
}

.advanced-select .input-group input {
  cursor: pointer;
}
</style>
