<template>
  <content-modal v-if="isVisible" @close="hideModal" class="advanced-search-modal">
    <template #header>
      <div>
        <h3>אוואנסירטער נאכזוך / חיפוש מתקדם / Advanced Search</h3>
      </div>
    </template>
    <template #body>
      <AdvancedSearchForm
          ref="theForm"
          :dummy="dummy"
          :authors-url="authorsUrl"
          :forms-url="formsUrl"
          :genres-url="genresUrl"
          :meter-options-url="meterOptionsUrl"
          :regions-url="regionsUrl"
          :scales-url="scalesUrl"
          :sources-url="sourcesUrl"
          :tempo-options-url="tempoOptionsUrl"
          :time-options-url="timeOptionsUrl"
          :titles-url="titlesUrl"
          :traditions-url="traditionsUrl"
          :dynasties-url="dynastiesUrl"
          :keywords-url="keywordsUrl"
          :translations="translations"
          :csrf-token="csrfToken"
          :authenticated="authenticated"
      ></AdvancedSearchForm>
    </template>
    <template #footer>
      <button class="btn clear" type="submit" @click.prevent="goToSearchPage">
        {{ translations.search }}
      </button>
      <button class="btn search" type="reset" @click.prevent="clearAllInputs">
        {{ translations.clear }}
      </button>
    </template>
  </content-modal>
</template>

<script setup>
import { defineProps, getCurrentInstance, nextTick, ref, watch } from "vue";
import ContentModal from "../../shared/components/ContentModal.vue";
import AdvancedSearchForm from "./AdvancedSearchForm.vue";

defineProps({
  authenticated: {
    type: Boolean,
    default: false
  },
  csrfToken: {
    type: String,
    required: true,
  },
  searchUrl: {
    type: String,
    required: true,
  },
  authorsUrl: {
    type: String,
    required: true,
  },
  formsUrl: {
    type: String,
    required: true,
  },
  genresUrl: {
    type: String,
    required: true,
  },
  meterOptionsUrl: {
    type: String,
    required: true,
  },
  regionsUrl: {
    type: String,
    required: true,
  },
  scalesUrl: {
    type: String,
    required: true,
  },
  sourcesUrl: {
    type: String,
    required: true,
  },
  tempoOptionsUrl: {
    type: String,
    required: true,
  },
  timeOptionsUrl: {
    type: String,
    required: true,
  },
  titlesUrl: {
    type: String,
    required: true,
  },
  traditionsUrl: {
    type: String,
    required: true,
  },
  dynastiesUrl: {
    type: String,
    required: true,
  },
  keywordsUrl: {
    type: String,
    required: true,
  },
  translations: {
    type: Object,
    required: true,
  },
});

const theForm = ref(null);
const isVisible = ref(false);
const eventBus = getCurrentInstance().appContext.config.globalProperties.$eventBus;

eventBus.on("advanced-search:show", showModal);

const dummy = ref(false);

watch(() => dummy.value, () => {
  if (dummy.value) {
    nextTick(() => {
      dummy.value = false;
    });
  }
});

function clearAllInputs() {
  dummy.value = true;

  const inputElements = document.querySelectorAll("input");

  inputElements.forEach((input) => {
    if (
      input.type !== "button" &&
      input.type !== "submit" &&
      input.type !== "reset"
    ) {
      input.value = "";
    }
  });
  clearLocalStorageByPrefix("advancedSelectQuery_");
}

function clearLocalStorageByPrefix(prefix) {
  for (let key in localStorage) {
    if (key.startsWith(prefix)) {
      localStorage.removeItem(key);
    }
  }
  localStorage.removeItem("params");
}

function showModal() {
  isVisible.value = true;
}

function hideModal() {
  isVisible.value = false;
}

function goToSearchPage() {
  theForm.value.submit();
}
</script>

<style>
.advanced-search-modal .modal-container {
  height: max-content;
}
</style>
