<template>
  <span class="button-container" @mouseenter="showTipNow" @mouseleave="hideTipNow" ref="buttonContainer">
    <button v-if="standalone" type="button" class="btn rounded-0 rounded-start edgebutton"/>
    <button
      v-if="haveMike"
      @click="startRecording"
      type="button"
      class="btn rounded-0 mike"
      data-bs-toggle="tooltip"
      data-bs-title="Start Recording">
      <i class="bi bi-mic-fill"></i>
    </button>

    <button
      v-if="haveStop"
      @click="stopRecording"
      type="button"
      class="btn rounded-0"
      data-bs-toggle="tooltip"
      title="Stop Recording">
      <i class="bi bi-stop-fill"></i>
    </button>

    <div v-if="haveTime" :class="['btn','timebutton','rounded-0',durationRangeClass]">
      <template v-if="playCounter">
        {{ playCounter }}s of
      </template>
      {{ timeCounter }}s
    </div>

    <button
      v-if="havePlay"
      @click="playRecording"
      type="button"
      class="btn rounded-0"
      data-bs-toggle="tooltip"
      title="Play Recording">
      <i class="bi bi-play-fill"></i>
    </button>

    <button
      v-if="haveUpload"
      @click="fileInput.click()"
      type="button"
      class="btn rounded-0"
      data-bs-toggle="tooltip"
      title="Upload tune">
      <i class="bi bi-upload"></i>
    </button>

    <template v-if="havePrefix">
      <input class="btn-check rounded-0" type="checkbox"
      name="humidprefix"
      title="Prefix search"
      :id="pfxId"
      />
      <label class="btn btn-secondary rounded-0 border-0" :for="pfxId"
        title="Prefix search">
        <i class="bi bi-align-start"></i>
      </label>
    </template>

    <button
      v-if="haveReset"
      @click="resetRecording"
      type="button"
      class="btn rounded-0"
      data-bs-toggle="tooltip"
      title="Reset Recording">
      <i v-if="!!signature" class="bi bi-music-note-list"></i>
      <i v-else-if="!!(haveFiles && fileType=='midi')" class="bi bi-file-music" />
      <i v-else-if="!!(haveFiles && fileType=='audio')" class="bi bi-speaker" />
      <i v-else-if="audioUrl" class="bi bi-soundwave" />
      <i class="bi bi-x-circle-fill"></i>
    </button>
    <button v-if="standalone" type="button" class="btn rounded-0 rounded-end edgebutton"/>
  </span>
  <teleport to="body">
    <div v-if="showTip" ref="theTip" class="position-absolute" :style="tooltipPosition" style="z-index: 20000">
      <div class="tooltip bs-tooltip-bottom fade show" role="tooltip">
        <div class="tooltip-inner">
          <p>bite zingt mitn farmakhtn moyl! (mmm <s>aaa</s>)</p>
          <p>please hum, keeping your lips closed! (mmm <s>aaa</s>)</p>
          <p>אנא זמזמו בפה סגור! (מממ <s>אאא</s>)</p>
          <img src="/static/src/vue/dist/humming-faces.png" />
        </div>
      </div>
    </div>
  </teleport>

  <input ref="fileInput" @change="onFileChanges"
    type="file" name="humming" hidden accept=".wav,.webm,.mp4,.mid,.midi">
  <input v-if="!!signature" type="hidden" name="signature" :value="signature" />

  <!-- Audio Player (Hidden, Used for Playing Recording) -->
  <audio ref="audioPlayer" :src="audioUrl" hidden>
  </audio>

</template>

<script setup>
import { ref, watch, defineProps, computed, nextTick, onMounted } from 'vue';
import { parseObject } from "../utils/objects.js";

const props = defineProps({
  standalone: {
    type: Boolean,
    default: false
  },
  minSeconds: {
    type: Number,
    default: 6
  },
  maxSeconds: {
    type: Number,
    default: 90
  },
  enableUpload: {
    type: Boolean,
    default: false
  }
});

const mimeTypes = ['audio/webm', 'audio/mp4'];
const isRecording = ref(false);
const timeCounter = ref(0);
const playCounter = ref(0);
const audioUrl = ref(null);
const audioChunks = ref([]);
let intervalId;
let mediaRecorder = null;
let mediaStream = null;
const audioPlayer = ref(null);
let mimeType = null;
const showTip = ref(false);
const theTip = ref(null);
const buttonContainer = ref(null);
const tooltipPosition = ref({
  top: '0px',
  left: '0px'
});
const signature = ref(null);

const haveMike = computed(() => !(haveFiles.value || signature.value || isRecording.value));
const haveStop = computed(() => !!isRecording.value);
const haveTime = computed(() => !!(audioUrl.value || isRecording.value));
const havePlay = computed(() => !!(audioUrl.value && !isRecording.value));
const haveReset = computed(() => !!((haveFiles.value || signature.value) && !isRecording.value));
const haveUpload = computed(() => !!(props.enableUpload && !haveFiles.value && !signature.value));
const havePrefix = computed(() => {
    if (haveFiles.value) {
        const fileName = fileInput.value.files[0].name;
        return fileName.endsWith('.mid') || fileName.endsWith('.midi');
    }else return !!signature.value;
});

const fileInput = ref(null);
const haveFiles = ref(false);
const fileType = ref(null);

const pfxId = ref('pfxID');

function onFileChanges() {
    if( (haveFiles.value = fileInput?.value?.files?.length > 0) ) {
        const t = fileInput.value.files[0].type;
        fileType.value = t.startsWith('audio/mid') ? 'midi' : 'audio';
    } else {
        fileType.value = null;
    }
}
function setFiles(files) {
    fileInput.value.files = files;
    onFileChanges();
}
function resetFiles() {
    fileInput.value.value = '';
    onFileChanges();
}
watch(haveFiles, x => {
    const form = audioPlayer.value.closest('form');
    if (form) {
        form.method = x ? 'POST' : 'GET';
        form.enctype = x ? 'multipart/form-data' : '';
    }
});

const durationRangeClass = computed(() => {
  if (timeCounter.value < props.minSeconds) {
    return 'duration-too-short';
  } else {
    return 'duration-ok';
  }
});

async function startRecording() {
  if (isRecording.value) {
    throw new Error('Recording is already in progress!');
  }
  resetRecording();
  if(!mediaStream) try {
      mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
  } catch (error) {
    console.error('Error accessing microphone:', error);
  }
  if (!mimeType) {
    mimeType = mimeTypes.find(type => MediaRecorder.isTypeSupported(type)) || mimeTypes[1];
  }
  try {
    mediaRecorder = new MediaRecorder(mediaStream, { mimeType });
    mediaRecorder.ondataavailable = (event) => {
      audioChunks.value.push(event.data);
    };
    mediaRecorder.onstop = () => {
      try {
        const audioBlob = new Blob(audioChunks.value, { type: mimeType });
        const dataTransfer = new DataTransfer();
        if (audioBlob.size > 0) {
          audioUrl.value = URL.createObjectURL(audioBlob);
          const audioFile = new File([audioBlob], 'recording.wav', { type: audioBlob.type });
          dataTransfer.items.add(audioFile);
        } else {
          audioUrl.value = null;
        }
        setFiles(dataTransfer.files);
      } finally {
        mediaStream.getTracks().forEach(track => track.stop());
      }
    };
    timeCounter.value = 0;
    audioChunks.value = [];
    mediaRecorder.start();
    isRecording.value = true;
    intervalId = setInterval(() => {
        ++timeCounter.value
        if (props.maxSeconds && timeCounter.value >= props.maxSeconds) {
          stopRecording();
        }
    }, 1000);
  } catch (error) {
    console.error('Error starting recording:', error);
  }
}

function stopRecording() {
  if (!mediaRecorder)
    throw new Error('Recording is not in progress!');
  mediaRecorder.stop();
  isRecording.value = false;
  clearInterval(intervalId);
}

function playRecording() {
  const player = audioPlayer.value;
  if (player && audioUrl.value) {
    player.play();
    playCounter.value=0;
    player.ontimeupdate = () => {
        if (player.currentTime < player.duration) {
            playCounter.value = Math.floor(player.currentTime);
        } else {
            playCounter.value = 0;
        }
    }
    player.onstop = () => {
        playCounter.value = 0;
    }
  } else {
    console.error('Audio player or audio URL is missing!');
  }
}

function resetRecording() {
  isRecording.value = false;
  timeCounter.value = 0;
  audioUrl.value = null;
  if (audioPlayer.value) {
    audioPlayer.value.pause();
    audioPlayer.value.currentTime = 0;
  }
  mediaRecorder = null;
  mediaStream = null;
  resetFiles();
  signature.value = null;
}

function showTipNow() {
  showTip.value = true;
  nextTick(() => {
    if (buttonContainer.value && theTip.value) {
      const buttonRect = buttonContainer.value.getBoundingClientRect();
      tooltipPosition.value = {
        top: `${buttonRect.bottom + window.scrollY + 5}px`, // Position below the button
        left: `${buttonRect.left + window.scrollX - 200}px`, // Adjust as needed
      };
    }
  });
}

function hideTipNow() {
  showTip.value = false;
}

onMounted(async () => {
    try {
        const { SIGNATURE } = parseObject("SEARCH_RESULTS_DATA");
        signature.value = SIGNATURE;
    } catch (error) { /* */ }
});

</script>

<style scoped>
.button-container {
  display: flex;
}
.edgebutton {
    padding: 0;
}

.timebutton {
    text-transform: none;
}
.duration-too-short {
  color: #dc3545;
}
.duration-ok {
  color: #28a745;
}

.tooltip-inner {
    background-color:rgb(44, 135, 151);
}
.tooltip, .tooltip-inner {
    width: 50ex;
    max-width: 50ex;
}

.tooltip p {
    margin: 0.5ex 1ex;
}
.tooltip img {
    margin: 0.5ex;
}

.mike {
    color: orange;
}
</style>
