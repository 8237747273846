<template>
    <button
        id="advanced_search_button"
        class="btn"
        @click="showAdvancedSearch"
    >
        {{ label }}
    </button>
</template>

<script setup>
import { getCurrentInstance, defineProps } from "vue";

defineProps({
    label: {
        type: String,
        required: true
    }
});

const eventBus = getCurrentInstance().appContext.config.globalProperties.$eventBus;

function showAdvancedSearch() {
    eventBus.emit("advanced-search:show");
}
</script>
